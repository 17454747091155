@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Montserrat:ital,wght@0,200;0,300;0,400;0,500;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Sevillana&display=swap');

body {
  margin: 0;
  padding-bottom: 3rem;
  font-family: 'Montserrat', 'Arial';
  background-color: #f1ffee;
  background-image:  repeating-radial-gradient( circle at 0 0, transparent 0, #639c630f 10px ), repeating-linear-gradient( #f1ffee55, #f1ffee );
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
